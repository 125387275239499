import React, { createContext, useState, useEffect, useCallback } from 'react'

import { Loader } from 'samespace-ui-kit'
import axios from 'axios'
import { getCookie, setCookie } from '../utils/cookies'

import { redirectSession, saveSession } from '../utils/session'
import { handleSignOut } from '../cookies'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const getAuthData = useCallback(async () => {
    // handleSignOut(false)
    const activeOrg = getCookie('activeOrg')
    const sessionId = getCookie('sessionId')
    const activeSpace = getCookie('activeSpace')
    const organizations = getCookie('organizations')
    if (!organizations || !activeSpace || !activeOrg || !sessionId) {
      return (window.location.href = `https://${
        process.env.REACT_APP_BASE_LOGIN_DOMAIN || 'login'
      }.${process.env.REACT_APP_BASE_API || 'ss.dev'}`)
    }
    const activeSpaceData = organizations[activeOrg].spaces.find(
      (item) => item.name === activeSpace
    )
    try {
      const { data: serviceTokenData } = await axios.post(
        `https://api.${process.env.REACT_APP_BASE_API || 'ss.dev'}/signin/api`,
        {
          operationName: 'getSession',
          query: `query getSession($id: String!, $orgId: Int, $spaceId: Int) {
            getSession(id: $id, orgId: $orgId, spaceId: $spaceId) {
              type
              value
            }
          }`,
          variables: {
            id: sessionId,
            orgId: +activeOrg,
            spaceId: +activeSpaceData.spaceId,
          },
        }
      )
      const session = serviceTokenData.data.getSession || []
      let userToken
      let serviceToken
      let ssoUrl

      userToken = session.find((item) => item.type === 'user').value
      setCookie('userToken', userToken)
      const indexOfServiceToken = session.findIndex(
        (item) => item.type === 'service'
      )
      const indexOfSsoUrl = session.findIndex((item) => item.type === 'sso')
      if (indexOfServiceToken > -1) {
        serviceToken = session[indexOfServiceToken].value
      }
      if (indexOfSsoUrl > -1) {
        ssoUrl = session[indexOfSsoUrl].value
      }
      if (serviceToken) {
        const { data } = await axios.post(
          `https://${activeSpaceData.instanceId}.samespace.com/auth/api/accounts`,
          {
            deviceType: 'webapp',
          },
          {
            headers: {
              Authorization: serviceToken,
            },
          }
        )
        setCookie('samespaceServiceToken', serviceToken)
        saveSession({
          authToken: data.token,
          userId: data.identity,
          cloudId: data.domainName,
          baseUrl: `https://${activeSpaceData.instanceId}.samespace.com`,
          ...data,
        })
        if (window.location.pathname === '/') {
          redirectSession()
        }
        setLoading(false)
      } else {
        window.location.href = ssoUrl
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          try {
            const { data } = await axios.post(
              `https://api.${
                process.env.REACT_APP_BASE_API || 'ss.dev'
              }/signin/api`,
              {
                query: `mutation RefreshSession($id: String!) {
                refreshSession(id: $id)
              }`,
                variables: {
                  id: sessionId,
                },
              }
            )
            if (
              data.errors &&
              data.errors[0] &&
              data.errors[0].extensions &&
              data.errors[0].extensions.code === 'SESSION_EXPIRED'
            ) {
              await axios.post(
                `https://api.${process.env.REACT_APP_BASE_API}/signin/api`,
                {
                  query: `mutation LogoutSession($id: String!) {
                logoutSession(id: $id)
              }`,
                  variables: {
                    id: sessionId,
                  },
                }
              )
              handleSignOut(true)
            } else {
              window.location.reload()
            }
          } catch (err) {
            window.location.href = `https://${
              process.env.REACT_APP_BASE_API || 'ss.dev'
            }`
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    getAuthData()
  }, [getAuthData])

  return (
    <AuthContext.Provider>
      {loading ? (
        <div className="initial-loader">
          <Loader />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  )
}
