import React, { useState, useEffect } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { App as AppWrapper, Loader, toast } from 'samespace-ui-kit'

import { Notice, ErrorBoundry, UnAuthorizedPage } from './components'
import { SessionContext } from './context/session'
import { ThemeProvider } from './context/theme'
import { AuthProvider } from './context/auth'
import { isInsideIframe } from './utils/cti'
import { removeCookie, getCookie } from './utils/cookies'
import { getAnalyticsLink, getCampaignsLink } from './utils/common'
import 'samespace-ui-kit/static/styles.css'
import './assets/icons/style.css'
import './assets/styles.scss'
import axios from 'axios'

import { Header } from 'samespace-zen'

const Manage = React.lazy(() =>
  import(/* webpackChunkName: 'manage' */ './sections/Manage/')
)
const Studio = React.lazy(() =>
  import(/* webpackChunkName: 'studio' */ './sections/Studio/')
)
const Insights = React.lazy(() =>
  import(/* webpackChunkName: 'insights' */ './sections/Insights/')
)
const Analytics = React.lazy(() =>
  import(/* webpackChunkName: 'analytics' */ './sections/Analytics/')
)
const Autodialer = React.lazy(() =>
  import(/* webpackChunkName: 'automate' */ './sections/Autodialer/')
)

const SSO = React.lazy(() =>
  import(/* webpackChunkName: 'sso' */ './pages/SSO')
)
const Login = React.lazy(() =>
  import(/* webpackChunkName: 'login' */ './pages/Login')
)
const InviteUser = React.lazy(() =>
  import(/* webpackChunkName: 'invite' */ './pages/Invite')
)
const VerifyEmail = React.lazy(() =>
  import(/* webpackChunkName: 'verify-email' */ './pages/VerifyEmail')
)
const ResendVerificationLink = React.lazy(() =>
  import(
    /* webpackChunkName: 'resend-verify-link' */ './pages/ResendVerificationLink'
  )
)
const SignUp = React.lazy(() =>
  import(/* webpackChunkName: 'signup' */ './pages/SignUp')
)
const Password = React.lazy(() =>
  import(/* webpackChunkName: 'password' */ './pages/Password')
)
const Activity = React.lazy(() =>
  import(/* webpackChunkName: 'activity' */ './pages/Activity')
)
const ActivityNew = React.lazy(() =>
  import(/* webpackChunkName: 'activity' */ './pages/ActivityNew')
)
const Billing = React.lazy(() =>
  import(/* webpackChunkName: 'billing' */ './pages/Billing')
)

function App() {
  const [sessionExpired, setSessionExpired] = useState(false)
  const isAdmin = getCookie('admin') === 'true'
  const isManager = getCookie('manage') === 'true'
  const isSupervisor = getCookie('insights') === 'true'
  const agent = !isAdmin && !isManager && !isSupervisor

  const allowInsights = isAdmin || isSupervisor
  const allowManage = isAdmin || isManager
  const allowCampaign = allowManage
  const analyticsLink = getAnalyticsLink()
  const campaignsLink = getCampaignsLink()

  const headerApps = [
    {
      key: 'stories',
      title: 'Stories',
      icon: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.6094 7.18273C13.6094 7.51728 13.3342 7.78849 12.9947 7.78849H0.614626C0.275178 7.78849 0 7.51728 0 7.18273V0.605754C0 0.271206 0.275177 0 0.614625 0H12.9947C13.3342 0 13.6094 0.271205 13.6094 0.605754V7.18273ZM7.24376 2.16368H2.19498C1.83155 2.16368 1.53654 2.45443 1.53654 2.81262C1.53654 3.1711 1.83155 3.46185 2.19498 3.46185H7.24376C7.60749 3.46185 7.9025 3.1711 7.9025 2.81262C7.9025 2.45443 7.60749 2.16368 7.24376 2.16368ZM11.6341 4.75972H2.19498C1.83155 4.75972 1.53654 5.05046 1.53654 5.40895C1.53654 5.76714 1.83155 6.05789 2.19498 6.05789H11.6341C11.9978 6.05789 12.2925 5.76714 12.2925 5.40895C12.2925 5.05046 11.9978 4.75972 11.6341 4.75972ZM0.614626 18C0.275178 18 0 17.7288 0 17.3943V10.8173C0 10.4827 0.275178 10.2115 0.614626 10.2115H17.3855C17.7249 10.2115 18.0001 10.4827 18.0001 10.8173V17.3943C18.0001 17.7288 17.7249 18 17.3855 18H0.614626ZM7.24376 12.3752H2.19498C1.83155 12.3752 1.53654 12.6657 1.53654 13.0241C1.53654 13.3826 1.83155 13.6731 2.19498 13.6731H7.24376C7.60749 13.6731 7.9025 13.3826 7.9025 13.0241C7.9025 12.6657 7.60749 12.3752 7.24376 12.3752ZM11.6341 14.9712H2.19498C1.83155 14.9712 1.53654 15.2617 1.53654 15.6202C1.53654 15.9787 1.83155 16.2691 2.19498 16.2691H11.6341C11.9978 16.2691 12.2925 15.9787 12.2925 15.6202C12.2925 15.2617 11.9978 14.9712 11.6341 14.9712Z"
            fill="white"
          />
        </svg>
      ),
      svgIcon: true,
      link: '/stories/all',
      enabled: true,
    },
    {
      key: 'analytics',
      title: 'Analytics',
      icon: 'stats',
      link: analyticsLink,
      enabled: true,
    },
    {
      key: 'pulse',
      title: 'Pulse',
      icon: 'pulse',
      link: '/insights/pulse-v2',
      enabled: allowInsights,
    },
    {
      key: 'teams',
      title: 'Users + Teams',
      icon: 'team',
      link: '/manage/users',
      enabled: allowManage,
    },
    {
      key: 'studio',
      title: 'Studio',
      icon: 'studio',
      link: '/studio',
      enabled: allowManage,
    },
    {
      key: 'campaigns',
      title: 'Playbooks',
      icon: 'automate',
      link: campaignsLink,
      enabled: allowCampaign,
    },
  ]

  useEffect(() => {
    const emailVerified = getCookie('emailVerified')
    const daysToVerify = getCookie('daysToVerify')

    if (emailVerified === 'false' && Number(daysToVerify) > 0) {
      toast.error(
        `Verify your email within ${daysToVerify} ${
          daysToVerify > 1 ? 'days' : 'day'
        } ${
          Number(daysToVerify) <= 2
            ? 'or you will not be able to signin after that'
            : ''
        } `
      )
    } else if (emailVerified === 'true') {
      axios
        .get('/manage/api/sso')
        .then(async (response) => {
          const { SSOEnabled } = response.data
          if (!SSOEnabled) {
            toast.success('Email Verified')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    removeCookie('emailVerified')
    removeCookie('daysToVerify')
  }, [])

  const isInIFrame = isInsideIframe()

  return (
    <SessionContext.Consumer>
      {({ session }) => (
        <AuthProvider>
          <ThemeProvider session={session}>
            <div className={`app ${isInIFrame && 'is-in-iframe'}`}>
              <AppWrapper basename="/">
                {({ history }) => {
                  return (
                    <>
                      {!isInIFrame &&
                        session.loggedIn &&
                        history.location.pathname !== '/' &&
                        !history.location.pathname.includes('sso/authorize') &&
                        !history.location.pathname.includes('email/verify') &&
                        !history.location.pathname.includes('account/status') &&
                        !history.location.pathname.includes('password') &&
                        !history.location.pathname.includes('invite') &&
                        !history.location.pathname.includes('signin') &&
                        !history.location.pathname.includes('signup') &&
                        !sessionExpired && (
                          <>
                            <Header
                              footerClass="samespace-footer"
                              headerClass="samespace-header samespace-modal-adjuster"
                              activityLogs={
                                isAdmin
                                  ? {
                                      tooltip: 'Logs',
                                      icon: (
                                        <span className="kit-icon-notification" />
                                      ),
                                      link: '/activity',
                                    }
                                  : null
                              }
                              activityLogsClickHandler={() => {
                                history.push('/activity')
                              }}
                              showSettings={isAdmin}
                              settingsOnClickHandler={() => {
                                history.push('/settings')
                              }}
                              navbarItems={headerApps
                                .filter((item) => item.enabled)
                                .map((item) => {
                                  return {
                                    tooltip: item.title,
                                    onClick: () => {
                                      history.push(item.link)
                                    },
                                    link: item.link,
                                    icon: item.svgIcon ? (
                                      item.icon
                                    ) : (
                                      <span
                                        className={`kit-icon-${item.icon}`}
                                      />
                                    ),
                                  }
                                })}
                            />
                            <Notice />
                          </>
                        )}
                      <Router history={history}>
                        <React.Suspense fallback={<Loader />}>
                          {session.loaded && (
                            <ErrorBoundry>
                              <Switch>
                                <Route exact path="/">
                                  <Login />
                                </Route>
                                <Route exact path="/sso/authorize">
                                  <SSO />
                                </Route>
                                <Route exact path="/invite/:inviteId?">
                                  <InviteUser />
                                </Route>
                                <Route exact path="/email/verify/:userId?">
                                  <VerifyEmail />
                                </Route>
                                <Route exact path="/account/status/:userId?">
                                  <ResendVerificationLink />
                                </Route>
                                <Route exact path="/signin/:cloudId?">
                                  <Login />
                                </Route>
                                <Route exact path="/signup/v2/:key">
                                  <SignUp />
                                </Route>
                                <Route exact path="/signup/:key">
                                  <SignUp />
                                </Route>
                                <Route exact path="/password/:page">
                                  <Password />
                                </Route>
                                {session.loggedIn && !sessionExpired ? (
                                  <>
                                    <Route exact path="/activity/old">
                                      {isManager || isAdmin ? (
                                        <Activity />
                                      ) : (
                                        <UnAuthorizedPage />
                                      )}
                                    </Route>
                                    <Route exact path="/activity">
                                      {isManager || isAdmin ? (
                                        <ActivityNew />
                                      ) : (
                                        <UnAuthorizedPage />
                                      )}
                                    </Route>
                                    <Route exact path="/billing/:section?">
                                      {isManager || isAdmin ? (
                                        <Billing />
                                      ) : (
                                        <UnAuthorizedPage />
                                      )}
                                    </Route>
                                    <Route path="/(manage|campaigns|onboarding)">
                                      {isManager || isAdmin ? (
                                        <Manage history={history} />
                                      ) : (
                                        <UnAuthorizedPage />
                                      )}
                                    </Route>
                                    <Route path="/settings">
                                      {isAdmin ? (
                                        <Manage history={history} />
                                      ) : (
                                        <UnAuthorizedPage />
                                      )}
                                    </Route>
                                    <Route path="/studio">
                                      {isManager || isAdmin ? (
                                        <Studio history={history} />
                                      ) : (
                                        <UnAuthorizedPage />
                                      )}
                                    </Route>
                                    <Route path="/insights">
                                      {!agent ? (
                                        <Insights history={history} />
                                      ) : (
                                        <UnAuthorizedPage />
                                      )}
                                    </Route>
                                    <Route path="/analytics">
                                      {!agent ? (
                                        <Analytics history={history} />
                                      ) : (
                                        <UnAuthorizedPage />
                                      )}
                                    </Route>
                                    <Route path="/playbooks">
                                      {isManager || isAdmin ? (
                                        <Autodialer history={history} />
                                      ) : (
                                        <UnAuthorizedPage />
                                      )}
                                    </Route>
                                    <Route path="/user-insights">
                                      <Manage history={history} />
                                    </Route>
                                    <Route path="/stories">
                                      <Manage history={history} />
                                    </Route>
                                  </>
                                ) : (
                                  <Redirect to="/" />
                                )}
                              </Switch>
                            </ErrorBoundry>
                          )}
                        </React.Suspense>
                      </Router>
                    </>
                  )
                }}
              </AppWrapper>
            </div>
          </ThemeProvider>
        </AuthProvider>
      )}
    </SessionContext.Consumer>
  )
}

export default App
