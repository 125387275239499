import React from 'react'
import ReactDOM from 'react-dom'
import Cookies from 'universal-cookie'

import App from './App'

import * as serviceWorker from './serviceWorker'

import { initialSession, SessionContext } from './context/session'

import './cookies'

class Samespace extends React.Component {
  constructor(props) {
    super(props)
    this.cookies = new Cookies()
    this.state = {
      session: initialSession,
    }
  }

  componentDidMount() {
    this.checkSession()
  }

  updateSession(session) {
    this.setState(() => ({
      session: session,
    }))
  }

  checkSession() {
    if (this.cookies.get('authToken')) {
      this.updateSession({
        loaded: true,
        loggedIn: true,
        emailVerified: this.cookies.get('emailVerified'),
        daysToVerify: this.cookies.get('daysToVerify'),
        baseURL: this.cookies.get('BASE_URL'),
        authToken: this.cookies.get('authToken'),
        refreshToken: this.cookies.get('refreshToken'),
        user: {
          id: this.cookies.get('userId'),
          name: this.cookies.get('name'),
        },
        privileges: {
          admin: this.cookies.get('admin') === 'true',
          insight: this.cookies.get('insight') === 'true',
          manage: this.cookies.get('manage') === 'true',
        },
        access: {
          campaign: this.cookies.get('campaign') === 'true',
          ivr: this.cookies.get('ivr') === 'true',
          mediaServer: this.cookies.get('mediaServer') === 'true',
          sipTrunk: this.cookies.get('sipTrunk') === 'true',
          verifyPhone: this.cookies.get('verifyPhone') === 'true',
        },
      })
    } else {
      this.updateSession({
        loaded: true,
      })
    }
  }

  render() {
    return (
      <SessionContext.Provider
        value={{
          session: this.state.session,
          updateSession: this.updateSession,
        }}
      >
        <App />
      </SessionContext.Provider>
    )
  }
}

ReactDOM.render(<Samespace />, document.getElementById('root'))
serviceWorker.unregister()
