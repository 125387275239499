import axios from 'axios'
import { Cookies } from 'samespace-ui-kit'

import { getHomeLink } from './utils/common'
import { setCookie, getAllCookie } from './utils/cookies'
import { getFrameURL, isInsideIframe } from './utils/cti'

export const RESERVED_DOMAINS = [
  'www',
  'local',
  'dev',
  'beta',
  'cloud',
  'engage',
]
export const PRODUCTION_DOMAINS = ['www', 'cloud']
export const REDIRECT_DOMAIN =
  process.env.NODE_ENV === 'production'
    ? 'https://www.samespace.com/signin'
    : 'http://local.samespace.com:3076'

export const COOKIE_EXPIRY_IN_MS = 30 * 24 * 3600 * 1000
export const COOKIE_DOMAIN = `.${process.env.REACT_APP_BASE_API || 'ss.dev'}`
export const COOKIE_SAVE_OPTIONS = {
  path: '/',
  maxAge: COOKIE_EXPIRY_IN_MS,
  domain: COOKIE_DOMAIN,
}
export const COOKIE_REMOVE_OPTIONS = { path: '/', domain: COOKIE_DOMAIN }

//execute
;(function () {
  checkSavedCookies()
  if (isInsideIframe()) {
    console.log('isInsideIframe 111 ---------------->')
  } else {
    document.addEventListener('visibilitychange', function () {
      validateActiveSession()
    })
  }
})()

function validateActiveSession() {
  const { savedcloud } = getAllCookie()
  if (document.hidden) {
    window.lastCloud = savedcloud
  } else {
    if (window.lastCloud !== savedcloud) {
      window.location.reload()
    }
  }
}

function checkSavedCookies() {
  try {
    const cookies = getAllCookie()
    if (isInsideIframe()) {
      if (cookies.BASE_URL && window.location.pathname !== getFrameURL()) {
        window.location.href = cookies.BASE_URL + getFrameURL()
      }
    } else {
      if (
        window.location.hostname.includes(
          `.${process.env.REACT_APP_BASE_API || 'ss.dev'}`
        )
      ) {
        let subdomain = window.location.host.split('.')[0]
        if (RESERVED_DOMAINS.includes(subdomain)) {
          handleReservedDomain()
        } else {
          if (
            window.location.pathname !== '/' &&
            window.location.pathname !== '/signin' &&
            !window.location.pathname.includes('/signup') &&
            !window.location.pathname.includes('/password') &&
            !window.location.pathname.includes('/sso/authorize') &&
            !window.location.pathname.includes('/invite') &&
            !window.location.pathname.includes('/email/verify') &&
            !window.location.pathname.includes('/account/status')
          ) {
            const cookies = getAllCookie()
            let accList = cookies.accList || []
            if (accList.length) {
              if (window.location.pathname.includes('/signin/')) {
                const parts = window.location.href.split('/')
                subdomain = parts[4]
              } else {
                subdomain = cookies.savedcloud || accList[0]
              }
              let account = null
              Object.keys(cookies).forEach((key) => {
                if (key.includes('account_')) {
                  const space = cookies[key]
                  if (space.BASE_URL === window.location.origin) {
                    account = cookies[key]
                  }
                }
              })
              if (!account || account.BASE_URL === window.location.origin) {
                account = cookies[`account_${subdomain}`]
              }
              if (cookies.authToken) {
                checkBilling()
              }
              if (account) {
                for (const [key, value] of Object.entries(account)) {
                  setCookie(key, value)
                }
                accList = accList.filter((o) => o !== subdomain)
                accList.unshift(subdomain)
                setCookie('accList', accList)
                setCookie('BASE_URL', account.BASE_URL)
              } else {
                checkDomain(subdomain)
              }
            } else {
              handleInvalidDomain()
            }
          }
        }
      } else {
        handleCustomDomain()
      }
    }
  } catch (e) {
    console.log('EX', e)
  }
}

function checkBilling() {
  axios
    .get(`${getAllCookie().BASE_URL}/manage/api/v2/selfcare/api`, {
      headers: { authorization: `JWT ${getAllCookie().authToken}` },
      withCredentials: false,
    })
    .then((info) => {
      setCookie('billing', true)
    })
    .catch(() => {
      document.cookie = `billing=;expires=${new Date().toUTCString()};`
    })
}

function checkDomain(domain) {
  if (RESERVED_DOMAINS.includes(domain)) {
    handleReservedDomain()
  } else {
    axios
      .create({
        transformRequest: [
          (data, headers) => {
            delete headers.authorization
            delete headers.common.authorization
            headers['Content-Type'] = 'application/json'
            return data
          },
        ],
        withCredentials: false,
      })
      .get(`https://api.samespace.com/hub/v1/space/${domain}`)
      .then((response) => {
        if (response.data.cid) {
          handleValidDomain()
        } else {
          handleInvalidDomain(true)
        }
      })
      .catch(() => {
        handleInvalidDomain(true)
      })
  }
}

function handleInvalidDomain(terminate) {
  const parts = window.location.href.split('/')
  if (!terminate && parts.length === 5 && parts[4]) {
    checkDomain(parts[4])
  } else {
    window.location.href = REDIRECT_DOMAIN
  }
}

function handleValidDomain() {
  if (!window.location.pathname.includes('signin')) {
    window.location.href = REDIRECT_DOMAIN
  }
}

function handleCustomDomain() {
  const cookies = getAllCookie()
  if (
    window.location.pathname === '/' ||
    window.location.pathname.includes('signin')
  ) {
    if (cookies && cookies.authToken) {
      window.location.href = getHomeLink()
    }
  }
}

function handleReservedDomain() {
  const subdomain = window.location.host.split('.')[0]
  const cookies = getAllCookie()
  if (
    !window.location.pathname.includes('/signin') &&
    !window.location.pathname.includes('/signup') &&
    !window.location.pathname.includes('/password') &&
    !window.location.pathname.includes('/sso/authorize')
  ) {
    const { savedcloud } = cookies
    if (savedcloud) {
      const cloudId = savedcloud
      const { _ga, _gid, accounts, accList, ...rest } = cookies
      const accountData = {}
      for (const [key, value] of Object.entries(rest)) {
        if (!key.includes('account_')) {
          accountData[key] = value
        }
      }
      let updateAccList = accList || []
      updateAccList = updateAccList.filter((o) => o !== cloudId)
      updateAccList.unshift(cloudId)
      setCookie(`account_${cloudId}`, accountData)
      setCookie(`accList`, updateAccList)
      // setCookie('BASE_URL', 'https://' + subdomain + '.samespace.com')
      if (PRODUCTION_DOMAINS.includes(subdomain)) {
        window.location.href = `https://${
          process.env.REACT_APP_BASE_DOMAIN || 'engage'
        }.${process.env.REACT_APP_BASE_API || 'ss.dev'}`
      }
    }
  }
}

export function handleSignOut(redirect) {
  const possibleDomains = [
    'localhost',
    '.ss.dev',
    '.samespace.com',
    'dev.samespace.com',
    'demo.samespace.com',
    'beta.samespace.com',
    'cloud.samespace.com',
    'relada.samespace.com',
  ]
  possibleDomains.forEach((domain) => {
    Cookies.delete('name', { path: '/', domain: domain })
    Cookies.delete('manage', { path: '/', domain: domain })
    Cookies.delete('admin', { path: '/', domain: domain })
    Cookies.delete('userId', { path: '/', domain: domain })
    Cookies.delete('agentId', { path: '/', domain: domain })
    Cookies.delete('insights', { path: '/', domain: domain })
    Cookies.delete('authToken', { path: '/', domain: domain })
    Cookies.delete('refreshToken', { path: '/', domain: domain })
    Cookies.delete('API_URL', { path: '/', domain: domain })
    Cookies.delete('WS_URL', { path: '/', domain: domain })
    Cookies.delete('domainId', { path: '/', domain: domain })
    Cookies.delete('timezone', { path: '/', domain: domain })
    Cookies.delete('groupId', { path: '/', domain: domain })
    Cookies.delete('groupName', { path: '/', domain: domain })
    Cookies.delete('reportType', { path: '/', domain: domain })
    Cookies.delete('reportEndDate', { path: '/', domain: domain })
    Cookies.delete('reportStartDate', { path: '/', domain: domain })
    Cookies.delete('WS_URL', { path: '/', domain: domain })
    Cookies.delete('BASE', { path: '/', domain: domain })
    Cookies.delete('ivr', { path: '/', domain: domain })
    Cookies.delete('campaign', { path: '/', domain: domain })
    Cookies.delete('sipTrunk', { path: '/', domain: domain })
    Cookies.delete('mediaServer', { path: '/', domain: domain })
    Cookies.delete('verifyPhone', { path: '/', domain: domain })
    Cookies.delete('billing', { path: '/', domain: domain })
  })
  localStorage.removeItem('INSIGHTS_HISTORY_TEAMOPTIONS')
  localStorage.removeItem('INSIGHTS_HISTORY_CURRENT')
  if (redirect) {
    window.location.href = `https://${
      process.env.REACT_APP_BASE_LOGIN_DOMAIN || 'login'
    }.${process.env.REACT_APP_BASE_API || 'ss.dev'}`
  }
}
