import { toast } from 'samespace-ui-kit'
import Cookies from 'universal-cookie'

export function toastErrors(err) {
  const { data = {} } = err || {}
  const { errors = [] } = data
  const message = errors.length
    ? errors.map((o) => o[Object.keys(o)[0]]).join('\n')
    : 'Something went wrong!'
  toast.error(message)
}

export function formatCurrency(value, currencyInfo = {}) {
  const { currency, rounding_digit, iso_code } = currencyInfo
  if ((value || value === 0) && currency) {
    var formatter = new Intl.NumberFormat(`en-${iso_code.toUpperCase()}`, {
      style: 'currency',
      currency: currency.toUpperCase(),
      minimumFractionDigits: rounding_digit,
      maximumFractionDigits: rounding_digit,
    })
    const formattedValue = formatter.format(value)
    const formattedSections = formattedValue.split('.')
    return formattedSections[1]
      ? formattedSections[0] + '.' + formattedSections[1]
      : formattedSections[0]
  } else {
    return '–'
  }
}

export function getCampaignsLink() {
  const cookies = new Cookies()
  const activeCampaign = cookies.get('activeCampaign')
  if (activeCampaign === 'classic') {
    return '/campaigns'
  } else if (activeCampaign === 'updated') {
    return '/playbooks'
  } else {
    return '/playbooks'
  }
}

export function getAnalyticsLink() {
  const cookies = new Cookies()
  const admin = cookies.get('admin') === 'true'
  const manage = cookies.get('manage') === 'true'
  const insights = cookies.get('insights') === 'true'
  const agentInsights = !admin && !manage && !insights
  return agentInsights ? '/user-insights' : '/analytics'
}

export function getManageLink() {
  return '/manage/users'
}

export function getHomeLink() {
  return '/stories'
}

export function getQueryParams(key, url = window.location.href) {
  const urlObject = new URL(url)
  return urlObject.searchParams.get(key)
}
