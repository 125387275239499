import React from 'react'
import axios from 'axios'
import { toast } from 'samespace-ui-kit'

import { RESERVED_DOMAINS } from './constants'
import { API_BASE, APIS } from './urls'
import { getHomeLink } from './common'
import { getAllCookie, setCookie, removeCookie, getCookie } from './cookies'

export function getAllSpaces() {
  const allCookies = getAllCookie()
  const { accList = [] } = allCookies
  let allSpaces = []
  accList.forEach((acc) => {
    allSpaces.push({
      ...allCookies[`account_${acc}`],
      cloudId: acc,
    })
  })

  allSpaces = allSpaces.filter((space) => {
    if (space.refreshToken) {
      const token = parseJwt(space.refreshToken)
      return Date.now() <= new Date(token.exp * 1000)
    }
    return false
  })
  return allSpaces
}

export function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export function getSavedSession() {
  const allSpaces = getAllSpaces()
  return allSpaces[0] || null
}

export function getSavedLogins() {
  const allSpaces = getAllSpaces()
  return allSpaces.map((data) => {
    return {
      domain: data.cloudId,
      name: data.name,
      profilePic: `${data.BASE_URL}/manage/api/user/image/${data.userId}.jpg`,
    }
  })
}

export async function verifyCloud(spaceId, fallbackToAuth) {
  const cloudId = spaceId.toLowerCase()
  const hostname = window.location.host
  const subdomain = hostname.split('.')[0]
  if (
    hostname.includes(`.${process.env.REACT_APP_BASE_API || 'ss.dev'}`) &&
    RESERVED_DOMAINS.includes(subdomain)
  ) {
    const hubResult = await verifyViaHub(cloudId)
    const result = fallbackToAuth
      ? await verifyViaAuthServer({
          cloudId,
          baseUrl: `https://${hubResult.url}`,
          devMode: true,
        })
      : false
    return result || hubResult
  } else {
    const result = await verifyViaAuthServer({ cloudId })
    return result
  }
}

async function verifyViaHub(cloudId) {
  try {
    const subdomain = window.location.host.split('.')[0]
    const response = await axios
      .create({
        transformRequest: [
          (data, headers) => {
            delete headers.authorization
            delete headers.common.authorization
            headers['Content-Type'] = 'application/json'
            return data
          },
        ],
        withCredentials: false,
      })
      .get(`https://api.samespace.com/hub/v1/space/${cloudId}`)
    if (RESERVED_DOMAINS.includes(subdomain)) {
      axios.defaults.baseURL = `https://${response.data.url}`
      return response.data
    } else {
      const { url } = response.data
      if (subdomain !== url && !RESERVED_DOMAINS.includes(subdomain)) {
        if (url.includes(`.${process.env.REACT_APP_BASE_API || 'ss.dev'}`)) {
          window.location.href = `https://${url}/signin/${cloudId}`
        } else {
          window.location.href = `https://${
            process.env.REACT_APP_BASE_DOMAIN || 'engage'
          }.${process.env.REACT_APP_BASE_API || 'ss.dev'}`
        }
      }
      return response.data
    }
  } catch (e) {
    if (e.response && e.response.data) {
      const { errors } = e.response.data
      errors.forEach((err) => {
        toast.error(err.message)
      })
    } else {
      toast.error(
        <span>
          Space&nbsp;
          <strong>
            <em>{cloudId}</em>
          </strong>
          &nbsp;is invalid
        </span>
      )
    }
    throw e
  }
}

export async function verifyViaAuthServer({
  cloudId,
  baseUrl = window.location.origin,
  devMode = false,
}) {
  const url = `${baseUrl}${API_BASE}${APIS.DOMAIN}`
  try {
    const response = await axios.post(url, {
      deviceType: 'webapp',
      domainName: cloudId,
    })

    if (response.data.redirect) {
      window.location = response.data.redirect
    } else {
      const subdomain = window.location.host.split('.')[0]
      return {
        cid: subdomain,
        url: window.location.hostname,
      }
    }
  } catch (error) {
    if (error) {
      // Cloud ID is inactive - 422 status code
      toast.error(
        (error.response && error.response.data && error.response.data.msg) ||
          'Something went wrong'
      )
    } else {
      return null
    }
  }
}

export async function handleLogin(payload) {
  const url = `${API_BASE}${APIS.LOGIN}`
  try {
    const response = await axios.post(url, {
      deviceType: 'webapp',
      domainName: payload.cloudId,
      username: payload.username,
      password: payload.password,
    })
    return response.data
  } catch (err) {
    const { msg } = err.response.data
    toast.error(msg || 'Something went wrong!')
    return err &&
      err.response &&
      err.response.data &&
      err.response.data.name === 'suspensionError'
      ? err
      : null
  }
}

export async function handleForgotPassword(payload) {
  const url = `${API_BASE}${APIS.FORGOT_PASSWORD}`
  try {
    await axios.post(url, {
      deviceType: 'webapp',
      domainName: payload.cloudId,
      email: payload.email,
    })
    //toast.info('Password changed')
    return true
  } catch (err) {
    const { msg } = err.response.data
    toast.error(msg || 'Something went wrong!')
    return false
  }
}

export async function verifyPasswordKey(key) {
  const url = `${API_BASE}${APIS.VERIFY_PASSWORD_KEY}`
  try {
    const response = await axios.post(url, { key })
    return !!response
  } catch (err) {
    return false
  }
}

export async function handleResetPassword(values) {
  const { key, password } = values
  const url = `${API_BASE}${APIS.RESET_PASSWORD}`
  try {
    const response = await axios.post(url, { key, password })
    toast.success('Password changed successfully')
    return !!response
  } catch (err) {
    const { msg } = err.response.data
    toast.error(msg || 'Something went wrong')
    return false
  }
}

export async function handleSSO(data) {
  saveSession({ ...data })
  if (getCookie('newUser')) {
    window.location.href = '/onboarding/create-team'
  } else {
    redirectSession()
  }
}

export function saveSession(data) {
  const allCookies = getAllCookie()
  const { accList } = allCookies
  const { cloudId } = data
  const sessionData = {
    savedcloud: cloudId,
    authToken: data.authToken,
    refreshToken: data.refreshToken,
    username: data.username,
    name: data.name,
    domainId: data.domainId,
    userId: data.userId,
    emailVerified: data.emailVerified,
    BASE_URL: data.BASE_URL || data.baseUrl,
    admin: getBooleanString(data.admin),
    manage: getBooleanString(data.manage),
    insights: getBooleanString(data.insights),
    ivr: true,
    mediaServer: true,
    sipTrunk: true,
    verifyPhone: false,
    campaign: true,
    newAnalytics: true,
  }
  if (data.firstUserLogin) {
    setCookie('newUser', true)
  }
  if (data.daysToVerify) {
    sessionData.daysToVerify = data.daysToVerify || '0'
  }
  for (const [key, value] of Object.entries(sessionData)) {
    setCookie(key, value)
  }
  setCookie(`account_${cloudId}`, {
    ...sessionData,
    updatedAt: Date.now(),
  })

  let updateAccList = accList || []
  if (updateAccList.includes(cloudId)) {
    updateAccList = updateAccList.filter((o) => o !== cloudId)
  }
  if (updateAccList.length >= 5) {
    const extraClouds = updateAccList.slice(5)
    extraClouds.forEach((acc) => {
      removeCookie(`account_${acc}`)
    })
    updateAccList = updateAccList.splice(0, 5)
  }
  updateAccList.unshift(cloudId)
  setCookie(`accList`, updateAccList)
}

export async function redirectSession() {
  window.location.href = getHomeLink()
}

function getBooleanString(val) {
  if (val && (val === true || val === 'true')) {
    return 'true'
  } else {
    return 'false'
  }
}
